<template>
  <div class="container-page">
    <p class="title-inner">Внешняя торговля - оборот товаров и услуг</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <ForeignTradeTurnoverWorldTable :nn="nn" />
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <div class="required">
          <ComponentInput
            label="Страна"
            :select2Settings="select2Settings"
            :select2Options="countries"
            v-model="dialogCountryId"
            type="select2"
            required
          />
        </div>
        <div class="required">
          <ComponentInput
            label="Вид операции"
            :select2Settings="select2Settings"
            :select2Options="tradingOperations"
            v-model="dialogTradingOperationId"
            type="select2"
            required
          />
        </div>
        <div class="required">
          <ComponentInput
            label="Вид предмета торговли"
            :select2Settings="select2Settings"
            :select2Options="tradeItemTypes"
            v-model="dialogTradeItemTypeId"
            type="select2"
            required
          />
        </div>
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import ImportModal from '@/components/modals/ImportModal';

  import NsiApi from '@/modules/nsi/api/nsi';

  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';
  import ForeignTradeTurnoverWorldTable from '../components/ForeignTradeTurnoverWorldTable';

  export default {
    name: 'ForeignTradeTurnoverWorld',
    components: { ImportModal, ModalComponent, ButtonStock, ComponentInput, ForeignTradeTurnoverWorldTable },
    mixins: [filtermanager],
    data() {
      return {
        route: 'foreignTradeTurnoverWorld',

        select2Settings: Constants.select2Settings,
        countries: [],
        tradingOperations: [],
        tradeItemTypes: [],

        importInProgress: false,
        importModal: false,
        dialogCountryId: null,
        dialogTradingOperationId: null,
        dialogTradeItemTypeId: null,
      };
    },
    created() {
      this.loadDictionaries();
    },
    methods: {
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsitradingoperation', this.defaultRequest)
          .then((response) => {
            this.tradingOperations = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        NsiApi.search('nsitradeitemtype', this.defaultRequest)
          .then((response) => {
            this.tradeItemTypes = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.closeModal();
        this.importInProgress = true;
        Api.uploadFile(
          this.route,
          file,
          this.dialogCountryId,
          this.dialogTradingOperationId,
          this.dialogTradeItemTypeId,
        )
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.nn++;
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      closeModal() {
        this.importModal = false;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        dialogCountryId: required,
        dialogTradingOperationId: required,
        dialogTradeItemTypeId: required,
      };
    },
  };
</script>

<style scoped></style>
